import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF"
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram"

import SocialIcon from "../SocialIcon/SocialIcon"
import { telephoneStrip } from "../../utils/utils"

import "./SurroundingCities.scss"

import ImageMeta from "../ImageMeta"

import ButtonGroupMap from "../Button/ButtonGroupMap"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import GeneralHours from "@bit/azheng.joshua-tree.general-hours"

const SurroundingCities = ({
  data,
  location,
  relatedLocations,
  sideColumns = 3,
}) => {
  const combinedLocations = [location].concat(relatedLocations)

  const allowedSocials = ["Facebook"]

  return (
    <div className="columns p-section">
      <div className={`column is-${sideColumns}`}></div>
      <div className="column">
        <MarkdownViewer markdown={data.content} />

        <div className="surrounding-cities">
          <div className="surrounding-cities__image">
            <ImageMeta
              publicId={data.imageId}
              cloudName="nuvolum"
              width="auto"
              responsive
            />
          </div>
          <div className="surrounding-cities__content">
            {combinedLocations.map((info) => (
              <div className="location-block" key={info.locationName}>
                <h4>{info.locationName} Office</h4>

                <div>
                  <a
                    href={info.googlePlaceLink}
                    target="_blank"
                    title={`Get directions to our ${info.locationName} location.`}
                  >
                    {`${info.streetNumber} ${info.streetName}`}
                    <div className="address-spacer"></div>
                    {`${info.cityName}, ${info.stateAbbr} ${info.cityZip}`}
                  </a>
                </div>

                <div className="mt-1">
                  <h7>Phone</h7>
                  <a
                    href={`tel:+1${telephoneStrip(info.phone)}`}
                    title="Give us a call"
                  >
                    {info.phone}
                  </a>
                </div>

                <GeneralHours
                  className="mt-1 hours"
                  language="en"
                  contact={true}
                  locationHours={info.locationHours}
                />
                {/* 
                  <div className="socials">
                    {info.socialAccounts.map((social) => (
                      <React.Fragment key={social.platform}>
                        {allowedSocials.includes(social.platform) && (
                          <a
                            href={social.link}
                            title={`Follow us on ${social.platform}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <SocialIcon social={social} />
                          </a>
                        )}
                      </React.Fragment>
                    ))}
                  </div> */}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={`column is-${sideColumns}`}></div>
    </div>
  )
}

export default SurroundingCities
