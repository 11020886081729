import React from "react"
import ImageMeta from "../../components/ImageMeta"
import Layout from "../../components/layout"
import Seamless from "../../components/Seamless"
import { SharedStateProvider } from "../../components/SharedState/PageSharedState"
import SEO from "../../components/seo"

import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF"
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram"
import GeneralHours from "@bit/azheng.joshua-tree.general-hours"
import SocialReviews from "../../components/SocialReviews/SocialReviews"
import { graphql } from "gatsby"

import SocialIcon from "../../components/SocialIcon/SocialIcon"

function alphabetizeCities(array) {
  array.sort((a, b) => {
    let cityA = a.locationData.locationName.toLowerCase()
    let cityB = b.locationData.locationName.toLowerCase()

    //sort string ascending
    if (cityA < cityB) return -1
    if (cityA > cityB) return 1
    return 0
  })
  return array
}

export default function LocationBlocks(props) {
  const amarilloLocations = []
  const txLocations = []

  props.locations.forEach((location) => {
    location.locationData.cityName === "Amarillo"
      ? amarilloLocations.push(location)
      : txLocations.push(location)
  })

  alphabetizeCities(txLocations)
  alphabetizeCities(amarilloLocations)

  return (
    <div className="location-blocks-container">
      <h3>Amarillo Offices</h3>
      <div className="office-flexbox">
        {amarilloLocations.map((locationObj) => {
          let location = locationObj.locationData
          return (
            <Block
              language={props.language}
              key={location.locationName}
              location={location}
            />
          )
        })}
      </div>
      <h3>Other Locations</h3>
      <div className="office-flexbox">
        {txLocations.map((locationObj) => {
          let location = locationObj.locationData
          return (
            <Block
              language={props.language}
              key={location.locationName}
              location={location}
            />
          )
        })}
      </div>
    </div>
  )
}

function Block(props) {
  {
    /* S DOS specific offices */
  }
  if (props.language === "es") {
    return (
      <>
        <div className="contact-sidebar-block">
          {props.location.cityZip === "92120" && (
            <h5>Dirección Oficina del Este</h5>
          )}
          {props.location.cityZip === "92117" && (
            <h5>Dirección Oficina de la Costa</h5>
          )}
          <a
            href={props.location.googlePlaceLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.location.streetNumber + " "}
            {props.location.streetName}
            <br />
            {props.location.cityName}
            {", " + props.location.stateAbbr + " "}
            {props.location.cityZip}
          </a>
        </div>
        <div className="contact-sidebar-block">
          <h7>{props.language === "es" ? "Teléfono" : "Phone"}</h7>
          <a href={"tel:+1" + props.location.phone.replace(/\D/g, "")}>
            {props.location.phone}
          </a>
        </div>

        <div
          className="contact-sidebar-block"
          style={{
            marginBottom:
              props.location.cityName === "Jacksonville" ? "60px" : "60px",
          }}
        >
          <GeneralHours
            language={props.language}
            contact={true}
            locationHours={props.location.locationHours}
          />
        </div>
        {/* {location.cityName !== "Jacksonville" && <div className="contact-reviews">
          <SocialReviews language={language} google city={location.cityName} paddingBottom={i === sidebarLocations.length - 1 ? "32px": ""} />
        </div>} */}
      </>
    )
  }

  return (
    <div>
      <div className="contact-sidebar-block">
        <h5>
          {props.location.locationName}{" "}
          {props.language === "es" ? "Dirección" : "Office"}
        </h5>

        <a
          href={props.location.googlePlaceLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.location.streetNumber + " "}
          {props.location.streetName}
          <br />
          {props.location.cityName}
          {", " + props.location.stateAbbr + " "}
          {props.location.cityZip}
        </a>
      </div>
      <div className="contact-sidebar-block">
        <h7>{props.language === "es" ? "Teléfono" : "Phone"}</h7>
        <a href={"tel:+1" + props.location.phone.replace(/\D/g, "")}>
          {props.location.phone}
        </a>
      </div>

      <div
        className="contact-sidebar-block"
        style={{
          marginBottom:
            props.location.cityName === "Jacksonville" ? "60px" : "60px",
        }}
      >
        <GeneralHours
          language={props.language}
          contact={true}
          locationHours={props.location.locationHours}
        />

        <div className="contact-sidebar-block socials">
          <h7>Find us on social</h7>
          {props.location.socialAccounts.map((social) => (
            <React.Fragment key={social.platform}>
              {/* {allowedSocials.includes(social.platform) && ( */}
              <a
                href={social.link}
                title={`Follow us on ${social.platform}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialIcon social={social} />
              </a>
              {/* )} */}
            </React.Fragment>
          ))}
        </div>
      </div>
      {/* {location.cityName !== "Jacksonville" && <div className="contact-reviews">
      <SocialReviews language={language} google city={location.cityName} paddingBottom={i === sidebarLocations.length - 1 ? "32px": ""} />
    </div>} */}
    </div>
  )
}
