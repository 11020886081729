import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF"
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram"

import "./SocialIcon.scss"


const SocialIcon = ({ social }) => {
  let icon
  if (social.platform === "Facebook") {
    icon = faFacebookF
  }
  if (social.platform === "Instagram") {
    icon = faInstagram
  }

  return (
    <div className="icon-circle">
      <FontAwesomeIcon className="button-icon" icon={icon} />
    </div>
  )
}

export default SocialIcon
